/* @import "../fonts/stylesheet.css"; @import "../fonts/stylesheet.css"; */
/* @import "../fontsawesome/css/all.css"; removed the equals sign @import "../fontsawesome/css/all.css"; */

font-family: Arial, sans-serif;

/*
font-family: Helvetica, arial, sans-serif;
font-family: 'Helvetica_Light';
font-family: Helvetica, arial, sans-serif;
 */

 /* text for add widgets dated: 2020-12-28 */
.lake_widget_section .lake_widget_heading {
    text-align:center;
}
.lake_widget_section .lake_widget_box {
    margin-top: 48px;
}
.lake_widget_section .lake_widget_box .lake_widget_box_wrap {
    display: flex;
    border-radius: 5px;
    border: 1px solid #80D8D3;
    overflow: hidden;
}
.lake_widget_section .lake_widget_box .lake_widget_box_wrap .lake_left_widget_box {
    border-right: 1px solid #80D8D3;
    width: 40%;
    text-align: center;
    padding: 24px;
}
.lake_widget_section .lake_widget_box .level_widget_box {
    overflow: hidden;
    border-radius: 8px;
    text-align: left;
}
.lake_widget_section .lake_widget_box .widget_header {
    color: #ffffff;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px 24px;
}
.lake_widget_section .lake_widget_box .widget_header .title {    
    width: 70%;
}
.lake_widget_section .lake_widget_box .widget_header .title .sub_title {
    font-size: 12px;
}
.lake_widget_section .lake_widget_box .widget_header .title h4 {
    margin: 6px 0 0;
    font-size: 18px;
}
.lake_widget_section .lake_widget_box .widget_header .header_widget_icon {
    width: 30%;
    text-align: right;
}
.lake_widget_section .lake_widget_box .widget_header .header_widget_icon img {
    width: 50%;
}
.lake_widget_section .lake_widget_box .widget_content {
    padding: 24px;
    background-color: #ffffff;
}
.lake_widget_section .lake_widget_box .widget_content .widget_date {
    color: #828282;
    font-size: 12px;
    position: relative;
}
.lake_widget_section .lake_widget_box .widget_content .widget_date img {
    position: absolute;
    left: 0;
    top: -4px;
    width: 16px;
}
.lake_widget_section .lake_widget_box .widget_content .widget_date span {
    padding-left: 24px;
}
.lake_widget_section .lake_widget_box .widget_content p {
    font-size: 20px;
    color: #595959;
}
.lake_widget_section .lake_widget_box .widget_content a {
    font-size: 14px;
}
.lake_widget_section .lake_widget_box .wheather_main_box {
    display: flex;
    margin: 16px 0;
}
.lake_widget_section .lake_widget_box .wheather_main_box .wheather_box {      
    width: 49%;
    text-align: center;
    padding: 10px;
    border: 1px solid #E9E9E9;
    border-radius: 8px;
}
.lake_widget_section .lake_widget_box .wheather_main_box .wheather_box:first-child {
    margin-right: 2%;
}
.lake_widget_section .lake_widget_box .wheather_main_box .wheather_box p {
    margin:0;
    font-size: 14px;
}
.lake_widget_section .lake_widget_box .wheather_main_box .wheather_box span {
    display: block;
    font-size: 12px;
    color: #888888;
}
.lake_widget_section .lake_widget_box .email_form {
    margin: 16px 0;
}
.lake_widget_section .lake_widget_box .email_form label {
    color: #828282;
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
}
.lake_widget_section .lake_widget_box .email_form input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #ABABAB;
    margin-bottom: 8px;
}
.lake_widget_section .lake_widget_box .email_form button {
    width: 100%;
    padding: 12px 16px;
    text-align: center;
    background-color: #6C83BD;
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    outline: none;
}
.lake_widget_section .lake_widget_box .calender_widget .widget_date {
    font-size: 14px;
    margin-bottom: 24px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 16px;
}
.lake_widget_section .lake_widget_box .calender_widget .widget_date span {
    display: block;
}
.lake_widget_section .lake_widget_box .calender_widget a.btn {
    font-size: 16px;
    text-decoration: none;
    display: flex;
    color: #ffffff;
    text-align: center;
    padding: 12px 16px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.lake_widget_section .lake_widget_box .calender_widget a.btn img {
    width: 14px;
    margin-left: 6px;
}
.lake_widget_section .lake_widget_box .widget_footer {
    padding:16px 24px;
}
.lake_widget_section .lake_widget_box .widget_footer .courtesy {
    color: #828282;
    font-size: 12px;
    position: relative;
}
.lake_widget_section .lake_widget_box .widget_footer .courtesy img {
    position: absolute;
    left: 0;
    top: 0px;
    width: 16px;
}
.lake_widget_section .lake_widget_box .widget_footer .courtesy_txt {
    padding-left: 24px;
}
.lake_widget_section .lake_widget_box .widget_footer .courtesy_txt span {
    display: block;
}
.lake_widget_section .lake_widget_box .widget_footer .courtesy_txt a {
    font-size: 14px;
}

.lake_widget_section .lake_widget_box .lake_widget_box_wrap .lake_right_widget_box {
    width: 60%;
    padding: 24px;
    background-color: #F3FBFB;
} 
.lake_right_widget_box .lake_right_widget_heading .title {
    display: flex;
}
.lake_right_widget_box .lake_right_widget_heading .title h4 {
    margin: 0 0 0 8px;
    color: #00927C;
}
.lake_right_widget_box .lake_right_widget_heading p {
    color: #595959;
}
.lake_right_widget_box textarea {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 5px;
    border: 1px solid #80D8D3;
    width: -webkit-fill-available;
    max-width: 100%;
}
.lake_right_widget_box .copy_code {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}
.lake_right_widget_box .copy_code span {
    margin-left: 8px;
}
.lake_right_widget_box .copy_code span a {
    color: #00927C;
}

/* 2020 LakeHub Color Swatches */

.lh-green { color: #00B2A9; }
.lh-green-lt { color: #80d8d3; }
.lh-green-dk { color: #00927C; }
.lh-green-deep { color: #065654; }

.lh-gray { color: #5B6770; }
.lh-gray-lt { color: #B5BBBF; }
.lh-gray-dk { color: #3c4449; }

.lh-blue { color: #27aae1; }
.lh-blue-lt { color: #a9dff4; }
.lh-blue-dk { color: #454da1; }

.lh-lime { color: #d7df23; }
.lh-orange { color: #EABE78; }
.lh-orange-dk { color: #E89354; }
.lh-orange-lt { color: #F2DA72; }


body {
    background: #F9F9F9;
    font-family: arial, sans-serif;
    color: #5b6870;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
    color: #5b6870;
}
h1 {
    font-family: arial, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 2.8em;
    font-weight: normal;
    letter-spacing: -1px
}

h1.lh-header-left {
    width: 70%;
    float: left
}

h2 {
    font-family: arial, sans-serif;
    margin: 0;
    padding: 7px 0 4px 0;
    letter-spacing: -0.06em;
    font-size: 1.8em;
    font-weight: normal;
    letter-spacing: 0
}

h3 {
    font-family: arial, sans-serif;
    font-size: 1.5em;
    margin: 0;
    padding: 6px 0 4px 0
}

h4 {
    font-family: arial, sans-serif;
    font-size: 1.2em;
    margin: 0;
    padding: 6px 0 4px 0
}

.columns {
    *zoom: 1
}

.columns:before,
.columns:after {
    content: " ";
    display: table
}

.columns:after {
    clear: both
}

.columns label {
    font-size: .9em
}

.columns.lh-background-header {
    position: relative;
    min-height: 300px;
    background: url("../images-v3/backdrops/backdrop.jpg") no-repeat 50% 0;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    -icab-border-radius: 3px 3px 0 0;
    -khtml-border-radius: 3px 3px 0 0;
    background-size: cover !important;
}

.columns.lh-background-header h1.lh {
    font-weight: normal;
    padding-top: 130px;
    padding-left: 25px;
    color: white;
    float: left;
    width: 50%;
    clear: both;
}

.columns.lh-background-header h2.lh {
    color: white;
    clear: both;
    width: 50%;
    font-family: arial, sans-serif;
    padding: 0;
    padding-left: 27px;
}

.columns.lh-background-header .lh-actions {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0px;
    overflow: hidden;
}
.columns.lh-background-header .lh-actions a.subaction {
    margin-top: 10px;
}

.columns.lh-background-header .lh-actions:before {
    content: "";
    position: absolute;
    width: 200%;  /* added to fix safari issues with mobile april 23, 2020; reverted to 100% APr 25 */
    height: 34px;
    background-color: #006253; /* SJT */
    border-bottom: 1px solid #01463c;
    top: 0px;
    left: -50%;
}

.columns.lh-background-header .lh-actions a {
    float: right;
    margin: 0 10px 0 10px;
    color: #fff;
    font-size: 11px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: arial, sans-serif;
}

.columns.lh-background-header .lh-actions a:hover {
    opacity: 1.0;
}

.columns.lh-background-header .lh-actions .banner-ad p {
    margin-top: 100px;
    float: right
}

.container {
    z-index: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1170px;
}

.container.menu {
    z-index: 4;
    width: 100%;
    background-color: #00B2A9; /* SJT */
    border-top: 1px solid #0fcfc5;
    max-width: 100%;
}
.container.menu:after {
    content: "";
    display: block;
    clear: both;
}
.container.menu .lh-menu {
    margin: 0 auto;
    max-width: 1170px;
    float: none;
    padding: 0 0;
}
.container#lh-top-container {
    width: 100%;
    max-width: 100%;
}

.container.menu-decoration {
    z-index: 4;
    display: none;
    width: 1040px
}

.menu {
    position: relative
}

.lh-menu {
/*     z-index: 5;
    background: white;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    -khtml-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    -icab-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    background: white;
    background: -moz-linear-gradient(top, white 0, #f2f2f2 97%, #e5e5e5 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(97%, #f2f2f2), color-stop(100%, #e5e5e5));
    background: -webkit-linear-gradient(top, white 0, #f2f2f2 97%, #e5e5e5 100%);
    background: -o-linear-gradient(top, white 0, #f2f2f2 97%, #e5e5e5 100%);
    background: -ms-linear-gradient(top, white 0, #f2f2f2 97%, #e5e5e5 100%);
    background: linear-gradient(to bottom, white 0, #f2f2f2 97%, #e5e5e5 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);
    border-bottom: 1px solid #ccc;
    -moz-border-radius: 1px 1px 1px 1px;
    -webkit-border-radius: 1px 1px 1px 1px;
    -o-border-radius: 1px 1px 1px 1px;
    -icab-border-radius: 1px 1px 1px 1px;
    -khtml-border-radius: 1px 1px 1px 1px */
}

.lh-menu ul {
    margin: 0;
    padding: 0
}

.lh-menu li {
    text-align: center;
    padding: 0;
   /*  background: url("../images-v3/OrangeStar-Tiny.png") no-repeat right 38% */
}


.lh-menu a.lh-menuitem {
    padding: 12px 15px;
    display: block;
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.lh-menu a.lh-menuitem:hover {
    background-color: rgba(0, 0, 0, 0.12);
}

.lh-menu-undermark {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #ccc;
    z-index: 1;
    margin-top: 30px
}

.lh-menu-undermark-right {
    float: right;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ccc;
    z-index: 1;
    margin-top: -30px
}

.lh-content {
    margin-top: 24px;
    padding: 0px 0 20px 0;
    background: white;
    border: 1px solid white;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -khtml-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -icab-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.container:last-child .lh-content {
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    -icab-border-radius: 0 0 4px 4px;
    -khtml-border-radius: 0 0 4px 4px
}

.lh-main {
    width: 100%;
    margin-left: -30%;
    float: right;
    min-height: 30px
}

.lh-main .lh-workspace {
    margin-left: 30%;
    min-height: 30px;
    padding: 10px
}

.lh-workspace {
    margin-left: 20px
}

.lh-sidebar {
    width: 30%;
    float: right;
    padding: 0 15px 15px 15px;
    min-height: 30px;
    position: relative;
}
.lh-sidebar:after {
    content: "";
    -webkit-box-shadow: 4px 0px 9px #eee;
    -moz-box-shadow: 4px 0px 9px #eee;
    box-shadow: 5px 0px 9px #eee;
    position: absolute;
    width: 10px;
    height: 200%;
    right: 0;
}

.lh-widget-sidebar {
    width: 280px;
    padding: 0 15px 15px 15px;
    min-height: 30px
}

.columns.lh-form {
    background: #f3f2ef;
    margin-top: 10px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px;
    padding: 5px 15px 10px 15px
}

.columns.lh-form p {
    padding: 5px 0 5px 0
}

a {
    color: #00B2a9;
}

a:hover {
    color: #00927c;
}

a.vote:hover {
    color: #c57c14
}

a.vote {
    color: black
}

.lh-sidebox {
/*     background: #e7e8ea;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -khtml-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -icab-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px; */
    min-height: 30px;
    margin-top: 20px;
    padding: 0;
    padding-bottom: 20px;
}


.lh-sidebox h2 {
/*     font-size: 1.0em;
    letter-spacing: -0.04em;
    padding: 7px 10px 8px 10px;
    border-bottom: 1px solid #ddd;
    margin: 4px 0 0 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0, white 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, white));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, white 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, white 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0, white 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, white 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0)
 */
    font-size: 1.1em;
    padding: 16px 0px 8px 0px;
    margin: 0;
    border-bottom: 1px solid #ced2d4;

}
.lh-sidebox > a > img {
    width: 100%;
}
.lh-sidebox .lh-sidebox-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px;
/*     border-top: 1px solid white; */
    padding: 20px 0 0 0;
}
.lh-sidebox .lh-sidebox-content:after {
    content: "";
    display: block;
    clear: both;
}
.lh-sidebox .lh-sidebox-content .columns {
    padding: 0px;
}

.lh-sidebox .lh-sidebox-content .columns.lh-tightleft {
    padding-left: 0
}

.lh-sidebox .lh-sidebox-content .columns.lh-tightright {
    padding-right: 0
}
.lh-sidebox .lh-sidebox-content.lh-weather {
    border: 1px solid #ced2d4;
    border-right: 0;
    padding-top: 0;
    border-top: 0;
}
.lh-sidebox .lh-sidebox-content.lh-weather .lh-tightleft {
    border: 1px solid #ced2d4;
    border-left: 0;
    border-top: 0;
    padding-top: 15px;
}
.lh-sidebox .lh-sidebox-content.lh-weather .lh-tightleft p {

}
.lh-sidebox .lh-sidebox-content.lh-weather .rule {
    min-height: 0;
}
.lh-sidebox .lh-sidebox-content.lh-weather .twelve {
    margin: 0;
    padding: 0;
}
.lh-sidebox .lh-sidebox-content.lh-weather hr.blank {
    display: none;
}
.lh-sidebox .lh-sidebox-content.lh-weather .twelve a {
    font-size: 18px;
    color: #444d9f;
    background-color: #fff;
    display: block;
    padding: 22px 0;
    margin: 0 0 -1px -1px;
}
.lh-sidebox p {
    font-size: .9em;
    line-height: 1.5;
    padding: 3px 1px 3px 1px;
    margin: 0
}
.lh-sidebox p[align="center"] a {
    font-size: 18px;
    color: #444d9f;
}

.lh-sidebox p.email-signup {
    padding: 0;
    padding-bottom: 15px;
    line-height: 1.5;
}

.lh-sidebox .lh-weather p {
    text-align: center;
    font-size: .85em;
    margin: 0;
    padding: 0
}

.lh-sidebox .lh-weather .columns {
    padding-top: 5px;
    padding-bottom: 15px
}

.lh-sidebox .lh-sponsors .columns {
    padding-top: 4px;
    text-align: center
}

.lh-sidebox .lh-customer-feedback p {
    font-size: .7em
}

.lh-sidebox-action {
    margin-bottom: 10px;
    text-align: center
}

.lh-sidebox-action a {
    font-size: 18px;
    color: #444d9f;
}

.lh-sidebox.lh-email {
/*     background: #ff6c00;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-box-shadow: none;
    -o-box-shadow: none;
    -icab-box-shadow: none */
}

.lh-sidebox.lh-email h2 {
/*     color: white;
    font-size: 1.0em;
    letter-spacing: -0.04em;
    font-weight: normal;
    border-bottom: 1px solid #9b4709;
    background: -moz-linear-gradient(top, rgba(251, 115, 15, 0) 0, #c45a0b 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(251, 115, 15, 0)), color-stop(100%, #c45a0b));
    background: -webkit-linear-gradient(top, rgba(251, 115, 15, 0) 0, #c45a0b 100%);
    background: -o-linear-gradient(top, rgba(251, 115, 15, 0) 0, #c45a0b 100%);
    background: -ms-linear-gradient(top, rgba(251, 115, 15, 0) 0, #c45a0b 100%);
    background: linear-gradient(to bottom, rgba(251, 115, 15, 0) 0, #c45a0b 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00fb730f', endColorstr='#c45a0b', GradientType=0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5) */
}

.lh-sidebox.lh-email .lh-sidebox-content {
/*     color: white;
    border-top: 1px solid #ff8f3c */
}

.lh-sidebox-no-margin-or-padding {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0
}
.lh-sidebox[class*="column"]:last-child {

}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content {
    padding: 0;
}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content p {
    margin: 0 !important;
    padding: 0 !important;
}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content p a {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid #e7e8ea;
    color: #444d9f;
    font-size: 14px;
    position: relative;
}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content p a:after {
    content: "\f105";
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    right: 15px;
    top: 10px;
}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content p a:hover {
    background-color: #e7e8ea;
}
.lh-sidebox[class*="column"]:last-child .lh-sidebox-content p:last-child a {
    border-bottom: 0;
}
/* .lh-sidebox[class*="column"]:last-child .lh-sidebox-content:before {
    content: "";
    position: absolute;
    width: 110%;
    height: 10px;
    background-color: #fff;
    bottom: -20px;
    left: -16px;
} */


.lh-fixed-column-offset {
    width: 100%;
    float: right;
    margin-left: -280px
}

.lh-workspace h1 {
/*     color: #00927c; */
    font-size: 1.8em;
    margin-top: 15px;
    margin-bottom: 5px;
    letter-spacing: 0
}

.lh-workspace h2.lh-detailprice {
    font-size: 1.3em;
    color: #444;
    margin: 0 0 10px 0;
    padding: 0
}

.lh-workspace p {
    font-size: 1.1em
}

p {
    margin: 0 0 10px 0;
/*     line-height: 140%; */
    padding: 0
}

p.lh-pagesummary {
    margin-bottom: 20px
}

.lh-list-header {
    margin-top: 10px
}

.lh-photo-header {
    margin-top: 0;
    padding-top: 0
}

.lh-sort-header {
    margin-top: 5px
}

.lh-item {
/*     padding-top: 12px */
}

.lh-item-footer {
    z-index: 0;
    border-bottom: 1px solid #E6E8EA;
    margin-top: 20px;
    clear: both;
    margin-bottom: 20px;
}

.lh-item-collection-footer {
    padding-bottom: 30px;
    margin-top: 10px
}

.lh-item-collection-footer a.btn,
.lh-item-collection-footer a.grey {
    float: left
}

.lh-ad-img {
    margin: 0;
    padding: 12px 0 0 0;
    min-height: 97px
}

.lh-wp-weather-image {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center
}

.lh-href-image-float-left {
    float: left;
    width: 82px;
    height: 82px;
    position: relative;
    z-index: 100
}

.lh-image-float-left {
    float: left;
    width: 85px;
    height: 85px;
    position: relative;
    z-index: 100
}

.lh-image {
    width: 85px;
}

.lh-image img {
    width: 85px;
    height: 85px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px
}

.lh-image-news {
    width: 85px;
}

.lh-image-news img {
    width: 85px;
    height: 85px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px
}

.lh-faq-holder {
    float: right;
    width: 100%;
    margin-left: -5px
}

.lh-faq-holder .lh-detail {
    margin-left: 5px
}

.lh-faq-holder .lh-actions {
    text-align: right;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-left: 10px;
    float: right
}

.lh-detail-holder {
    float: right;
    width: 100%;
    margin-left: -105px;
}

.lh-detail-holder .lh-detail {
    margin-left: 105px;
    padding: 15px;
}

.lh-detail-holder .lh-actions {
    text-align: right;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-left: 10px;
    float: right
}
.lh-detail-holder .lh-actions .btn {
    font-size: 10px;
}

/* FEATURED LISTING */

.lh-detail-featured.columns {
	padding: 0;
}

.lh-detail-featured {
    background-color: #fff;
}

.lh-detail-featured .lh-detail-holder {
    background-color: #fdfde1;
}

.lh-image {
	top: 15px;
	left: 15px;
	position: relative;
}


.lh-news {
    margin-left: -95px
}

.lh-news .lh-detail {
    margin-left: 105px
}

.lh-href-news-float-left {
    float: left;
    width: 85px;
    height: 85px;
    position: relative;
    z-index: 100
}

.lh-title {
    font-weight: bold;
    font-size: 1.2em
}

p.lh-contrib-ty {
    font-weight: bold
}

p.lh-contrib-ty a {
    text-decoration: underline
}

a.lh-a-underline {
    text-decoration: underline
}

h3.lh-itemtitle,
h4.lh-itemtitle {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 1px 0;
    padding: 0 0 8px 0
}

p.lh-itemprice,
p.lh-itemfeatures {
    font-weight: bold;
    color: #444;
    font-size: 1.2em;
}

p.lh-itemprice span,
p.lh-itemfeatures span {
    font-size: .8em;
    text-transform: uppercase;
    margin-right: 3px
}

p.lh-itemprice span.lh-featured,
p.lh-itemfeatures span.lh-featured {
    background-color: #d6de23;
    font-weight: bold;
    padding: 3px 10px;
    font-size: 9px;
}

p.lh-itemprice span.lh-waterfront,
p.lh-itemfeatures span.lh-waterfront {
    background-color: #444d9f;
    font-weight: normal;
    padding: 3px 10px;
    font-size: 9px;
}

p.lh-itemprice span.lh-new,
p.lh-itemfeatures span.lh-new {
    background-color: #E89354;
    font-size: 9px;
    font-weight: 700;
}

p.lh-itemsummary {
/*     font-size: .9em;
    line-height: 140%; */
    margin-top: 5px;
    clear: both;
    word-wrap: break-word
}

p.lh-byline {
    font-size: .8em;
    line-height: 140%;
    margin: 0;
    color: #b5bbbf;
    margin-top: -8px;
/*     width: 50%;
    float: left */
}

p.lh-newsaction {
    font-weight: bold;
    font-size: .8em;
    margin: 5px 0 0 0;
}
p.lh-newsaction a {
    color: #00B2a9;
}

p.lh-mobiledate {
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
    color: #888
}

a.lh-more {
    font-size: .8em;
    line-height: 110%;
    float: right;
    margin-top: 3px;
    font-weight: bold;
    color: #00B2A9;
}

a.lh-more:hover { color: #00927c; }

a.lh-photo-more {
    font-size: .8em;
    font-weight: bold;
/*     line-height: 110%;
    margin-top: 3px;
    margin-left: 0;
    margin-right: 20px;
    padding: 0;
    color: #008da7 */
}

a.lh-more-lake-info {
/*     font-size: .9em;
    line-height: 110%;
    margin-top: 3px;
    font-weight: bold;
    color: #008da7 */
}

p.lh-sort {
    float: right;
    font-size: .8em;
    margin: 0;
    padding: 0;
    font-weight: bold
}

p.lh-paging {
    font-size: .9em;
    text-align: right
}

p.lh-paging span {
    border-left: 1px solid #ddd;
    padding-left: 8px;
    padding-right: 4px
}

.lh-detailshare {
    text-align: right
}

.lh-detailfeatures>span {
    font-size: .9em
}

.lh-detailfeatures span.title {
    font-size: .8em;
    color: #888
}

.lh-detailfeatures .columns {
    margin-bottom: 10px;
    margin-top: 10px
}

.lh-photo {
    padding-top: 10px
}

.lh-photo a.lh-viewmore {
    text-align: right;
    font-size: .8em;
    font-weight: bold
}

.lh-img-border-news {
    border: 2px solid #53390c
}

.lh-img-border-prop {
    border: 3px solid #53390c
}

.lh-detaildescription {
    margin-top: 20px;
    word-wrap: break-word
}

.lh-label-inline-small {
    line-height: 32px;
    margin: 0 0 4px 0
}

.arrow-tiny-left {
    float: right;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #444d9f;
    z-index: 1;
    margin-top: 5px;
    margin-left: 6px
}

.photo-arrow-tiny-left {
    float: right;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #008da7;
    z-index: 1;
    margin-top: 5px;
    margin-left: 6px
}

.lh-date {
/*     background: #f8f8f8;
    border: 1px solid #ccc;
    color: #222;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -o-border-radius: 2px 2px 2px 2px;
    -icab-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -khtml-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -icab-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) */
    background: #b5bbbf;
    color: #fff;
    border-radius: 12px;
}

.lh-date p {
    text-align: center;
    margin: 0 auto;
    padding: 2px 0;
    border-radius: 0 0 8px 8px;
}

.lh-date p.mo {
    /*     border-bottom: 1px solid #ddd;
    font-weight: normal;
    font-size: .70em;
    line-height: 160%;
    background: white;
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    -icab-border-radius: 2px 2px 0 0;
    -khtml-border-radius: 2px 2px 0 0 */
    background: #9da4a9;
    font-family: arial, sans-serif;
    color: #fff;
    font-size: 11px;
}

.lh-date p.date {
    font-weight: bold;
    font-size: 1.50em;
    line-height: 120%;
/*     border-top: 1px solid white */
}

.lh-date p.day {
    font-weight: bold;
    font-size: .70em;
    line-height: 160%;
    margin-top: -3px;
    margin-bottom: 2px
}

hr.lh {
    height: 2px;
    background: #e7e8ea;
/*     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -khtml-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -icab-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
    margin: 4px 0 12px 0;
    border: 0
}

.columns hr.lh {
    margin: 4px 0 20px 0;
}

.columns.lh-form hr.lh {
    height: 2px;
    background: #ccc;
    border-bottom: 1px solid #fff;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-box-shadow: none;
    -o-box-shadow: none;
    -icab-box-shadow: none;
    margin: 4px 0 12px 0
}

hr.blank {
    height: 1px;
    background: 0;
    margin: 4px 0 6px 0;
    border: 0
}

hr.blank-small {
    height: 1px;
    background: 0;
    margin: 1px 0 1px 0;
    border: 0
}

hr.lh-dotted {
    height: 1px;
    background: 0;
    border-top: 1px dashed #aaa;
    margin: 4px 0 0 0
}

hr.lh-solidlight {
    height: 1px;
    background: 0;
    border-top: 1px solid #dadada;
    margin: 4px 0 0 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-box-shadow: none;
    -o-box-shadow: none;
    -icab-box-shadow: none
}

div.bottom {
    margin-bottom: 10px
}

a.btn,
input.btn,
button.btn {
    text-align: center;
/*     -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    -o-border-radius: 4px 4px 4px 4px;
    -icab-border-radius: 4px 4px 4px 4px;
    -khtml-border-radius: 4px 4px 4px 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -khtml-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -icab-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #767676;
    padding: 6px 8px 6px 8px;
    font-size: 14px;
    color: #5e2b06;
    background: white;
    background: -moz-linear-gradient(top, white 2%, #fff7c5 4%, #ffc771 96%, #c6821b 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(2%, white), color-stop(4%, #fff7c5), color-stop(96%, #ffc771), color-stop(100%, #c6821b));
    background: -webkit-linear-gradient(top, white 2%, #fff7c5 4%, #ffc771 96%, #c6821b 100%);
    background: -o-linear-gradient(top, white 2%, #fff7c5 4%, #ffc771 96%, #c6821b 100%);
    background: -ms-linear-gradient(top, white 2%, #fff7c5 4%, #ffc771 96%, #c6821b 100%);
    background: linear-gradient(to bottom, white 2%, #fff7c5 4%, #ffc771 96%, #c6821b 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#c6821b', GradientType=0);
    font-weight: bold;
    display: block */

    text-align: center;
    text-align: center;
    background-color: #00b1a8;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 8px 12px 6px 12px;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-family: arial, sans-serif;
    letter-spacing: 1px;
    outline: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

a.btn:hover,
input.btn:hover,
button.btn:hover {
    background-color: #00927c;
}

a.btn.grey,
input.btn.grey,
button.btn.grey {

}

a.btn.blue {
    background-color: #25a9e0;
}

a.btn.blue:hover,
input.btn.blue:hover,
button.btn.blue:hover {
    background-color: #2097c8;
}

a.btn.email-signup {
    width: 150px;
    padding: 12px 16px 10px 16px;
}

a.btn.inline {
    display: inline;
    text-align: center
}

a.btn.lh-header-right {
    float: right;
    margin-top: 8px;
    padding: 6px 8px 6px 8px
}

input.box {
    border: 1px solid #aaa;
    width: 100%;
    font-size: 1em;
    outline: 0;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    -o-border-radius: 4px 4px 4px 4px;
    -icab-border-radius: 4px 4px 4px 4px;
    -khtml-border-radius: 4px 4px 4px 4px;
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    -webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    -khtml-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    -o-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    -icab-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
    padding: 6px 6px 6px 6px;
    background: #eaeaea;
    background: -moz-linear-gradient(top, #eaeaea 0, white 7%, white 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eaeaea), color-stop(7%, white), color-stop(100%, white));
    background: -webkit-linear-gradient(top, #eaeaea 0, white 7%, white 100%);
    background: -o-linear-gradient(top, #eaeaea 0, white 7%, white 100%);
    background: -ms-linear-gradient(top, #eaeaea 0, white 7%, white 100%);
    background: linear-gradient(to bottom, #eaeaea 0, white 7%, white 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#ffffff', GradientType=0)
}

.lh-tightleft,
.columns.lh-tightleft {
    padding-left: 0
}

.lh-tightright {
    padding-right: 0
}

.lh-margin-tightright {
    margin-right: 0
}

.cl {
    *zoom: 1
}

.cl:before,
.cl:after {
    content: " ";
    display: table
}

.cl:after {
    clear: both
}

.reveal-modal .close-reveal-modal {
    font-size: 1em;
    text-shadow: none
}


/* TABS */

.tabs dd a, .tabs li a {
	color: #5B6770;
	font-weight: 400;
}

.tabs dd.active a, .tabs li.active a {
	color: #5B6770;
	font-weight: 700;
}

/* RESPONSIVE */


@media only screen and (max-width:1024px) {
    .columns.lh-background-header .lh-actions .banner-ad p {
        margin-top: 140px;
    }
    .columns.lh-background-header h1.lh {
        padding-top: 80px;
    }
    .lh-menu a.lh-menuitem {
        padding: 12px 10px;
    }

}

/* added June 20, 2022 */
@media (min-width: 1200px) {
   .logo_text {
     width: 50%;
     margin: auto;
   }
}


@media only screen and (max-width:769px) {
/*     .lh-background-header h2 {
        display: none
    } */
    .lh-workspace p,
    .lh-sidebox p {
        font-size: 14px;
    }
    .columns.lh-background-header h2.lh,
    .columns.lh-background-header h1.lh {
        width: 100%;
    }
    .lh-background-header h1 {
        width: 100%;
    }
    .columns.lh-background-header .lh-actions .banner-ad p {
        float: left;
    }
    .slicknav_menu {
        padding: 0 !important;
    }
    .slicknav_btn {
        margin: 0 !important;
        text-decoration: none !important;
        text-shadow: none !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        display: block !important;
        width: 100% !important;
        padding: 15px 25px !important;
        text-transform: uppercase !important;
        font-weight: normal !important;
        letter-spacing: 1px !important;
    }
    .slicknav_menu .slicknav_menutxt {
        color: #fff !important;
        font-weight: bold !important;
        text-shadow: /* 0 1px 3px #000 !important */ none;
        font-size: 14px !important;
        padding-top: 2px !important;
    }
    .slicknav_nav a {
        margin: 0 !important;
    }
    .slicknav_menu .slicknav_icon {
        float: right !important;
    }

    .slicknav_menu .slicknav_icon-bar {
    	box-shadow: none;
    }

    .lh-menu a.lh-menuitem {
        padding: 8px 25px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 16px;
        background-color: #25237f;
        outline: none;
        text-align: left;
        position: relative;
    }
    .lh-menu a.lh-menuitem:after {
        content: "\f105";
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        right: 25px;
        top: 10px;
    }
    .lh-menu a.lh-menuitem:hover {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: transparent;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.2);
    }
    .columns.lh-background-header .lh-actions a {
        float: left;
        margin: 0 10px 10px 10px;
        letter-spacing: 0; /* added to fix safari issues with mobile april 23, 2020 */
    }
    .lh-menu li {
        /* padding: 8px 18px 15px 10px */
    }
    .columns.lh-background-header h1.lh {
        width: 100%;
        font-size: 2.6em
    }
    .lh-fixed-column-offset {
        width: 100%
    }
    a.lh-viewall {
        float: left
    }
    p.lh-sort {
        float: left;
        margin-top: 10px
    }
    /* p.lh-itemprice span,
    p.lh-itemfeatures span {
        margin-left: 0;
        display: block;
        float: left;
        clear: both;
        margin-top: 2px;
        margin-right: 10px
    } */
    hr.lh {
        margin: 4px -26px 0 -26px;
        border: 0
    }
    .lh-main {
        margin-left: auto;
        float: none
    }
    .lh-sidebar {
        float: none;
        width: 100%
    }
    .lh-main .lh-workspace {
        margin-left: 0
    }
    .js #slick-menu {
        display: none
    }
    .js .slicknav_menu {
        display: block
    }
    .lh-menu li {
        background: 0
    }
    /* added april 23, 2020 to deal with banner ads making top links not clickable */
    .banner-ad {
      z-index: 1;
    }
    .lh-actions subaction {
      z-index: 99;
    }
    /* end april 23, 2020 changes */
}

.lh-image-view-contrib img {
    display: block;
    margin: 0 auto;
    border: 1px solid #aaa;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px
}

.lh-contrib-list img {
    border: 1px solid #aaa;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -icab-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px;
    width: 100%;
}

span.checkbox-text {
    font-size: .85em
}

span.label-text {
    font-size: .9em;
    line-height: 32px;
    margin: 0 0 12px 0
}

p.lh-view-article {
    margin-top: 10px;
    margin-bottom: 3px;
    margin-left: 5px;
    padding: 0;
    color: gray
}

p.lh-blog-article {
    font-size: 1.0em;
    padding: 0;
    word-wrap: break-word
}

/* 5-21-2021 Removal to create updated combined group below this entry for lists legibility
div.lh-blog-article li {
    line-height: 1.25em;
    margin-left: 15px;
    font-size: .9em
}

div.lh-blog-article-mob li {
    line-height: 1.25em;
    margin-left: 15px;
    font-size: .9em
}

*/

div.lh-blog-article-mob li, div.lh-blog-article li {
    line-height: 1.4em;
    margin-left: 15px;
    font-size: 1.1em;
    margin-top: 1em;
}

.lh-invoice-paid {
    color: #390
}

.lh-invoice-unpaid {
    color: #f00
}

footer {
    background: #fff;
    margin-top: 10px;
    color: #444;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -35px;
    min-height: 50px;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    -khtml-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    -icab-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
}

footer p {
    color: #888;
    text-align: center;
    font-size: 11px;
    margin-bottom: 100px;
}

footer a {
    color: #888;
    text-decoration: underline
}

.facebook-like-box {
    width: 280px;
    height: 170px;
    overflow: hidden;
    position: relative
}

.fb-container>div {
    margin: -2px 0 0 -2px
}

.columns.twelve.rule {
    padding: 0;
    margin: 0;
    line-height: 1px
}

.slicknav_menu {
    display: none
}

ul, ol, dl {
    padding: 0 0 0 16px;
    }

@media only screen and (max-width:767px) {
    p.lh-mobiledate {
        font-weight: normal;
        font-style: normal;
        margin: 0;
        padding: 0;
        color: #181c62;
        font-size: 18px !important;
        width: 100%;
        display: block !important;
        font-family: arial, sans-serif;
    }
    .lh-sidebox .lh-sponsors .columns.six {
        width: 50% !important;
        float: left;
    }
    .lh-sidebox .lh-sidebox-content.lh-weather .lh-tightleft {
        width: 50% !important;
        float: left;
    }
    .columns.lh-background-header {
        min-height: 250px;
    }
    .columns.lh-background-header .lh-actions .banner-ad p {
        margin-top: 120px;
    }

    /* added april 23, 2020 to deal with banner ads making top links not clickable */
    .banner-ad {
      z-index: 1;
    }
    .lh-actions .subaction {
      z-index: 99;
    }
    /* end april 23, 2020 changes */
}
@media only screen and (max-width:680px) {
      /* added april 23, 2020 to deal with banner ads making top links not clickable */
      .banner-ad {
        z-index: 1;
      }
      .lh-actions .subaction {
        z-index: 99;
      }
      /* end april 23, 2020 changes */
}
@media only screen and (max-width:769px) {
    .lake_widget_section .lake_widget_box .lake_widget_box_wrap {
        flex-wrap: wrap;
    }
    .lake_widget_section .lake_widget_box .lake_widget_box_wrap .lake_left_widget_box {
        padding: 32px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #80D8D3;
    }
    .lake_widget_section .lake_widget_box .lake_widget_box_wrap .lake_right_widget_box {
        width: 100%;
    }
}
